.button {
  min-width: 200px;
  min-height: 40px;
  border-radius: 6px;
  border: none;
  background-color: #222;
  color: #FFF;
  cursor: pointer;
  /* font-size: 1rem; */
}

/* .button:disabled {
  background-color: #777;
} */

.button:hover {
  color: #AAA;
}

.mid-button {
  min-width: 200px;
}

.small-button {
  min-width: 100px;
  /* font-size: 0.85rem; */
}

.info {
  background-color: #444;
  color: #FFF;
}

.success {
  background-color: green;
}

.warning {
  background-color: orange;
}

.danger {
  background-color: red;
}

.clean {
  background-color: #EEE;
  color: #222;
}

.text-danger {
  color: red;
}

.text-warning {
  color: orange;
}

.bold {
  font-weight: bold;
}

.text-shadow {
  color: #777;
}

.underline {
  text-decoration: underline;
}

.wide {
  width: 100% !important;
}

.stack-container {
  background-color: #EEE;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 6;
}

.row-container {
  background-color: #EEE;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 10;
  margin-right: 10;
  flex: 1;
  gap: 6;
}

.space {
  margin-top: 15px;
  margin-bottom: 15px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.fade-in-anim {
  animation: fade-in 0.3s;
}

.fade-out-anim {
  animation: fade-out 0.3s;
}

.white {
  background-color: #FFF;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes fade-out {
  from {
    opacity: 100;
  }
  to {
    opacity: 0;
  }
}